import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useFinanceStore } from '@/lib/store';
import { Search, Trash2, Info } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';
import { toast } from 'sonner';
import { CATEGORY_INFO, type TransactionCategory } from '@/lib/types';
import { Badge } from '@/components/ui/badge';
import { formatCurrency } from '@/lib/utils';

const sortOptions = {
  'date-desc': { label: 'Latest First', sort: (a: Date, b: Date) => b.getTime() - a.getTime() },
  'date-asc': { label: 'Oldest First', sort: (a: Date, b: Date) => a.getTime() - b.getTime() },
  'amount-desc': { label: 'Highest Amount', sort: (a: Date, b: Date) => b.getTime() - a.getTime() },
  'amount-asc': { label: 'Lowest Amount', sort: (a: Date, b: Date) => a.getTime() - b.getTime() },
};

export default function ExpenseList() {
  const { transactions, deleteTransaction } = useFinanceStore();
  const expenses = transactions.filter(t => t.type === 'expense');
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('date-desc');
  const [selectedExpense, setSelectedExpense] = useState<number | null>(null);
  const [categoryFilter, setCategoryFilter] = useState<TransactionCategory | 'all'>('all');

  const filteredExpenses = expenses
    .filter(expense => 
      (categoryFilter === 'all' || expense.category === categoryFilter) &&
      (expense.description.toLowerCase().includes(search.toLowerCase()) ||
       expense.purpose?.toLowerCase().includes(search.toLowerCase()))
    )
    .sort((a, b) => {
      if (sortBy.startsWith('date')) {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortBy === 'date-desc' ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
      } else {
        return sortBy === 'amount-desc' ? b.amount - a.amount : a.amount - b.amount;
      }
    });

  const handleDelete = async () => {
    if (selectedExpense) {
      try {
        await deleteTransaction(selectedExpense);
        setSelectedExpense(null);
      } catch (error) {
        console.error('Failed to delete expense:', error);
        toast.error('Failed to delete expense');
      }
    }
  };

  return (
    <Card className="p-6">
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search expenses..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="pl-9"
          />
        </div>
        <Select value={categoryFilter} onValueChange={(value) => setCategoryFilter(value as TransactionCategory | 'all')}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Category" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Categories</SelectItem>
            {Object.entries(CATEGORY_INFO).map(([key, { label, icon }]) => (
              <SelectItem key={key} value={key}>
                <span className="flex items-center">
                  <span className="mr-2">{icon}</span>
                  {label}
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select value={sortBy} onValueChange={setSortBy}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Sort by" />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(sortOptions).map(([value, { label }]) => (
              <SelectItem key={value} value={value}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-4">
        {filteredExpenses.length === 0 ? (
          <div className="text-center py-8 text-muted-foreground">
            No expenses found
          </div>
        ) : (
          filteredExpenses.map(expense => (
            <div
              key={expense.id}
              className="flex items-center justify-between p-4 bg-muted/50 rounded-lg hover:bg-muted/70 transition-colors"
            >
              <div className="flex items-center space-x-4">
                <div className="text-2xl">{CATEGORY_INFO[expense.category].icon}</div>
                <div>
                  <div className="flex items-center gap-2">
                    <p className="font-medium">{expense.description}</p>
                    {expense.purpose && (
                      <HoverCard>
                        <HoverCardTrigger>
                          <Info className="h-4 w-4 text-muted-foreground" />
                        </HoverCardTrigger>
                        <HoverCardContent>
                          <p className="text-sm">{expense.purpose}</p>
                        </HoverCardContent>
                      </HoverCard>
                    )}
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <Badge variant="secondary" className="text-xs">
                      {CATEGORY_INFO[expense.category].label}
                    </Badge>
                    <span className="text-xs text-muted-foreground">
                      {new Date(expense.date).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <span className="font-semibold text-destructive">
                  -{formatCurrency(expense.amount)}
                </span>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setSelectedExpense(expense.id)}
                >
                  <Trash2 className="h-4 w-4 text-muted-foreground hover:text-destructive" />
                </Button>
              </div>
            </div>
          ))
        )}
      </div>

      <AlertDialog open={!!selectedExpense} onOpenChange={() => setSelectedExpense(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Expense</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this expense? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete} className="bg-destructive text-destructive-foreground hover:bg-destructive/90">
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Card>
  );
}