import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { db } from './db';
import { toast } from 'sonner';
import type { Profile, Transaction, Preference, TodoItem } from './db-types';
import type { TransactionCategory } from './types';

interface FinanceState {
  initialized: boolean;
  isLoading: boolean;
  currentView: string;
  userProfile: {
    name: string;
    email: string;
    partnerName: string;
    weddingDate?: string;
    currency: string;
  };
  goal: number;
  savings: number;
  dailyLimit: number;
  transactions: Transaction[];
  todos: TodoItem[];
  preferences: {
    theme: 'light' | 'dark' | 'system';
    notifications: boolean;
    currency: string;
  };
  initializeData: () => Promise<void>;
  updateUserProfile: (profile: Partial<Profile>) => Promise<void>;
  updatePreferences: (preferences: Partial<Preference>) => Promise<void>;
  updateDailyLimit: (limit: number) => Promise<void>;
  updateGoal: (goal: number) => Promise<void>;
  addTransaction: (transaction: Pick<Transaction, 'type' | 'amount' | 'description' | 'category' | 'purpose'>) => Promise<void>;
  deleteTransaction: (id: number) => Promise<void>;
  addTodo: (todo: Omit<TodoItem, 'id' | 'user_id' | 'created_at' | 'completed_at'>) => Promise<void>;
  updateTodo: (id: number, updates: Partial<TodoItem>) => Promise<void>;
  deleteTodo: (id: number) => Promise<void>;
  getTodayExpenses: () => Transaction[];
  getMonthlyExpenses: () => Transaction[];
  getRemainingDaily: () => number;
  getProgress: () => number;
  calculateSavings: () => void;
  setCurrentView: (view: string) => void;
  reset: () => void;
}

const DEFAULT_PROFILE = {
  name: '',
  email: '',
  partnerName: '',
  currency: 'DZD',
};

const DEFAULT_PREFERENCES = {
  theme: 'light' as const,
  notifications: true,
  currency: 'DZD',
};

export const useFinanceStore = create<FinanceState>()(
  persist(
    (set, get) => ({
      initialized: false,
      isLoading: false,
      currentView: 'dashboard',
      userProfile: DEFAULT_PROFILE,
      goal: 1000000,
      savings: 0,
      dailyLimit: 5000,
      transactions: [],
      todos: [],
      preferences: DEFAULT_PREFERENCES,

      setCurrentView: (view) => set({ currentView: view }),

      initializeData: async () => {
        if (get().initialized) return;
        
        set({ isLoading: true });
        try {
          const [profile, transactions, preferences, todos] = await Promise.all([
            db.profiles.get(),
            db.transactions.list(),
            db.preferences.get(),
            db.todos.list()
          ]);

          set({
            userProfile: {
              name: profile?.name || '',
              email: profile?.email || '',
              partnerName: profile?.partner_name || '',
              weddingDate: profile?.wedding_date,
              currency: preferences?.currency || 'DZD',
            },
            goal: profile?.savings_goal || 1000000,
            dailyLimit: profile?.daily_limit || 5000,
            preferences: {
              theme: preferences?.theme || 'light',
              notifications: preferences?.notifications ?? true,
              currency: preferences?.currency || 'DZD',
            },
            transactions,
            todos,
            initialized: true,
          });

          get().calculateSavings();

        } catch (error) {
          console.error('Failed to initialize data:', error);
          toast.error('Failed to load your data. Please try again.');
          set({
            userProfile: DEFAULT_PROFILE,
            preferences: DEFAULT_PREFERENCES,
            initialized: true,
          });
        } finally {
          set({ isLoading: false });
        }
      },

      updateUserProfile: async (profile) => {
        try {
          const updated = await db.profiles.upsert({
            ...profile,
            email: profile.email || get().userProfile.email, // Ensure email is always included
          });
          set((state) => ({
            userProfile: {
              ...state.userProfile,
              email: updated.email || state.userProfile.email,
              name: updated.name || '',
              partnerName: updated.partner_name || '',
              weddingDate: updated.wedding_date,
            },
          }));
          toast.success('Profile updated successfully');
        } catch (error) {
          console.error('Failed to update profile:', error);
          toast.error('Failed to update profile');
          throw error;
        }
      },

      updatePreferences: async (preferences) => {
        try {
          await db.preferences.upsert(preferences);
          set((state) => ({
            preferences: {
              ...state.preferences,
              ...preferences,
            },
          }));
          toast.success('Preferences updated successfully');
        } catch (error) {
          console.error('Failed to update preferences:', error);
          toast.error('Failed to update preferences');
          throw error;
        }
      },

      updateDailyLimit: async (limit) => {
        try {
          await db.profiles.upsert({ 
            daily_limit: limit,
            email: get().userProfile.email // Include email in the update
          });
          set({ dailyLimit: limit });
          toast.success('Daily limit updated successfully');
        } catch (error) {
          console.error('Failed to update daily limit:', error);
          toast.error('Failed to update daily limit');
          throw error;
        }
      },

      updateGoal: async (goal) => {
        try {
          await db.profiles.upsert({ 
            savings_goal: goal,
            email: get().userProfile.email // Include email in the update
          });
          set({ goal });
          toast.success('Savings goal updated successfully');
        } catch (error) {
          console.error('Failed to update goal:', error);
          toast.error('Failed to update goal');
          throw error;
        }
      },

      addTransaction: async (transaction) => {
        try {
          const newTransaction = await db.transactions.add({
            ...transaction,
            date: new Date().toISOString().split('T')[0],
          });
          
          set((state) => ({
            transactions: [newTransaction, ...state.transactions],
          }));
          
          get().calculateSavings();
          toast.success('Transaction added successfully');
        } catch (error) {
          console.error('Failed to add transaction:', error);
          toast.error('Failed to add transaction');
          throw error;
        }
      },

      deleteTransaction: async (id) => {
        try {
          await db.transactions.delete(id);
          set((state) => ({
            transactions: state.transactions.filter(t => t.id !== id),
          }));
          get().calculateSavings();
          toast.success('Transaction deleted successfully');
        } catch (error) {
          console.error('Failed to delete transaction:', error);
          toast.error('Failed to delete transaction');
          throw error;
        }
      },

      addTodo: async (todo) => {
        try {
          const newTodo = await db.todos.add(todo);
          set((state) => ({
            todos: [newTodo, ...state.todos],
          }));
          toast.success('Item added to shopping list');
        } catch (error) {
          console.error('Failed to add todo:', error);
          toast.error('Failed to add item');
          throw error;
        }
      },

      updateTodo: async (id, updates) => {
        try {
          const updatedTodo = await db.todos.update(id, updates);
          set((state) => ({
            todos: state.todos.map((t) => (t.id === id ? updatedTodo : t)),
          }));
          toast.success('Item updated successfully');
        } catch (error) {
          console.error('Failed to update todo:', error);
          toast.error('Failed to update item');
          throw error;
        }
      },

      deleteTodo: async (id) => {
        try {
          await db.todos.delete(id);
          set((state) => ({
            todos: state.todos.filter((t) => t.id !== id),
          }));
          toast.success('Item removed from list');
        } catch (error) {
          console.error('Failed to delete todo:', error);
          toast.error('Failed to remove item');
          throw error;
        }
      },

      getTodayExpenses: () => {
        const today = new Date().toISOString().split('T')[0];
        return get().transactions.filter(
          t => t.type === 'expense' && t.date === today
        );
      },

      getMonthlyExpenses: () => {
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
          .toISOString()
          .split('T')[0];
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)
          .toISOString()
          .split('T')[0];

        return get().transactions.filter(
          t => t.type === 'expense' && 
          t.date >= firstDayOfMonth && 
          t.date <= lastDayOfMonth
        );
      },

      getRemainingDaily: () => {
        const todayExpenses = get().getTodayExpenses();
        const totalToday = todayExpenses.reduce((sum, t) => sum + t.amount, 0);
        return Math.max(0, get().dailyLimit - totalToday);
      },

      getProgress: () => {
        const goal = get().goal;
        if (goal <= 0) return 0;
        return Math.min(100, (get().savings / goal) * 100);
      },

      calculateSavings: () => {
        const transactions = get().transactions;
        const savings = transactions.reduce((sum, t) => 
          t.type === 'expense' ? sum - t.amount : sum + t.amount, 0);
        set({ savings: Math.max(0, savings) });
      },

      reset: () => {
        set({
          initialized: false,
          isLoading: false,
          currentView: 'dashboard',
          userProfile: DEFAULT_PROFILE,
          goal: 1000000,
          savings: 0,
          dailyLimit: 5000,
          transactions: [],
          todos: [],
          preferences: DEFAULT_PREFERENCES,
        });
      },
    }),
    {
      name: 'finance-storage',
      partialize: (state) => ({
        userProfile: state.userProfile,
        preferences: state.preferences,
        goal: state.goal,
        dailyLimit: state.dailyLimit,
        currentView: state.currentView,
      }),
    }
  )
);