import { Card } from './ui/card';
import { Button } from './ui/button';
import { ScrollArea } from './ui/scroll-area';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from './ui/dialog';
import { Input } from './ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { PlusCircle, ArrowUpCircle, ArrowDownCircle } from 'lucide-react';
import { useFinanceStore } from '@/lib/store';
import { useState } from 'react';
import { toast } from 'sonner';
import { formatCurrency } from '@/lib/utils';

export default function RecentTransactions() {
  const { transactions, addTransaction } = useFinanceStore();
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState<'expense' | 'income'>('expense');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const transactionAmount = parseFloat(amount);
    
    if (!isNaN(transactionAmount) && transactionAmount > 0 && description.trim()) {
      addTransaction({
        type,
        amount: transactionAmount,
        description: description.trim(),
        category: 'others'
      });
      
      toast.success(`${type === 'expense' ? 'Expense' : 'Income'} added successfully`);
      setAmount('');
      setDescription('');
      setOpen(false);
    } else {
      toast.error('Please enter a valid amount and description');
    }
  };

  return (
    <Card className="p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold">Recent Activity</h2>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <Button variant="outline" size="sm">
              <PlusCircle className="h-4 w-4 mr-2" />
              Add Transaction
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Transaction</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleSubmit} className="space-y-4">
              <Select
                value={type}
                onValueChange={(value: 'expense' | 'income') => setType(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Transaction Type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="expense">Expense</SelectItem>
                  <SelectItem value="income">Income</SelectItem>
                </SelectContent>
              </Select>
              <Input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                step="0.01"
                min="0"
              />
              <Input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Button type="submit" className="w-full">
                Add Transaction
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </div>
      <ScrollArea className="h-[400px]">
        <div className="space-y-4 pr-4">
          {transactions.length === 0 ? (
            <div className="text-center text-muted-foreground py-8">
              No transactions yet. Add your first transaction to get started!
            </div>
          ) : (
            transactions.map((transaction) => (
              <div
                key={transaction.id}
                className="flex items-center justify-between p-3 bg-muted/50 rounded-lg"
              >
                <div className="flex items-center space-x-4">
                  {transaction.type === 'expense' ? (
                    <ArrowDownCircle className="h-8 w-8 text-red-500" />
                  ) : (
                    <ArrowUpCircle className="h-8 w-8 text-green-500" />
                  )}
                  <div>
                    <p className="font-medium">{transaction.description}</p>
                    <p className="text-sm text-muted-foreground">
                      {new Date(transaction.date).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <span
                  className={`font-semibold ${
                    transaction.type === 'expense'
                      ? 'text-red-500'
                      : 'text-green-500'
                  }`}
                >
                  {transaction.type === 'expense' ? '-' : '+'}
                  {formatCurrency(transaction.amount)}
                </span>
              </div>
            ))
          )}
        </div>
      </ScrollArea>
    </Card>
  );
}