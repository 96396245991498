import { useState, useMemo } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Badge } from '@/components/ui/badge';
import { Progress } from '@/components/ui/progress';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useFinanceStore } from '@/lib/store';
import { TODO_CATEGORIES, type TodoItem } from '@/lib/types';
import { ListPlus, Check, Clock, AlertCircle, Trash2, Search, Filter, SortAsc, Gift, ShoppingBag } from 'lucide-react';
import { toast } from 'sonner';
import { formatCurrency } from '@/lib/utils';
import { motion, AnimatePresence } from 'framer-motion';

const PRIORITY_COLORS = {
  low: 'bg-blue-100 text-blue-700',
  medium: 'bg-yellow-100 text-yellow-700',
  high: 'bg-red-100 text-red-700',
};

const STATUS_COLORS = {
  pending: 'bg-gray-100 text-gray-700',
  in_progress: 'bg-blue-100 text-blue-700',
  completed: 'bg-green-100 text-green-700',
};

const SORT_OPTIONS = {
  'created-desc': { label: 'Newest First', sort: (a: TodoItem, b: TodoItem) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime() },
  'created-asc': { label: 'Oldest First', sort: (a: TodoItem, b: TodoItem) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime() },
  'cost-desc': { label: 'Highest Cost', sort: (a: TodoItem, b: TodoItem) => b.estimated_cost - a.estimated_cost },
  'cost-asc': { label: 'Lowest Cost', sort: (a: TodoItem, b: TodoItem) => a.estimated_cost - b.estimated_cost },
  'priority-desc': { label: 'Highest Priority', sort: (a: TodoItem, b: TodoItem) => b.priority.localeCompare(a.priority) },
  'priority-asc': { label: 'Lowest Priority', sort: (a: TodoItem, b: TodoItem) => a.priority.localeCompare(b.priority) },
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  show: { y: 0, opacity: 1 }
};

export default function TodosPage() {
  const { todos, addTodo, updateTodo, deleteTodo } = useFinanceStore();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState<'all' | 'pending' | 'completed'>('all');
  const [search, setSearch] = useState('');
  const [categoryFilter, setCategoryFilter] = useState<string>('all');
  const [sortBy, setSortBy] = useState<keyof typeof SORT_OPTIONS>('created-desc');
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    estimated_cost: '',
    priority: 'medium',
    category: 'other',
    status: 'pending',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const cost = parseFloat(formData.estimated_cost);
    
    if (!formData.title.trim()) {
      toast.error('Please enter a title');
      return;
    }

    if (isNaN(cost) || cost < 0) {
      toast.error('Please enter a valid cost');
      return;
    }

    setIsSubmitting(true);
    try {
      await addTodo({
        title: formData.title.trim(),
        description: formData.description.trim() || undefined,
        estimated_cost: cost,
        priority: formData.priority as TodoItem['priority'],
        category: formData.category as TodoItem['category'],
        status: formData.status as TodoItem['status'],
      });

      setFormData({
        title: '',
        description: '',
        estimated_cost: '',
        priority: 'medium',
        category: 'other',
        status: 'pending',
      });
      setOpen(false);
      toast.success('Item added to your shopping list! 🎉');
    } catch (error) {
      console.error('Failed to add item:', error);
      toast.error('Failed to add item');
    } finally {
      setIsSubmitting(false);
    }
  };

  const filteredAndSortedTodos = useMemo(() => {
    return todos
      .filter(todo => {
        const matchesTab = activeTab === 'all' || 
          (activeTab === 'pending' ? todo.status !== 'completed' : todo.status === 'completed');
        const matchesSearch = todo.title.toLowerCase().includes(search.toLowerCase()) ||
          todo.description?.toLowerCase().includes(search.toLowerCase());
        const matchesCategory = categoryFilter === 'all' || todo.category === categoryFilter;
        return matchesTab && matchesSearch && matchesCategory;
      })
      .sort(SORT_OPTIONS[sortBy].sort);
  }, [todos, activeTab, search, categoryFilter, sortBy]);

  const stats = useMemo(() => {
    const totalCost = filteredAndSortedTodos.reduce((sum, todo) => sum + todo.estimated_cost, 0);
    const completedCost = filteredAndSortedTodos
      .filter(todo => todo.status === 'completed')
      .reduce((sum, todo) => sum + todo.estimated_cost, 0);
    const progress = totalCost ? (completedCost / totalCost) * 100 : 0;
    
    const byPriority = {
      high: filteredAndSortedTodos.filter(t => t.priority === 'high').length,
      medium: filteredAndSortedTodos.filter(t => t.priority === 'medium').length,
      low: filteredAndSortedTodos.filter(t => t.priority === 'low').length,
    };

    const byStatus = {
      pending: filteredAndSortedTodos.filter(t => t.status === 'pending').length,
      in_progress: filteredAndSortedTodos.filter(t => t.status === 'in_progress').length,
      completed: filteredAndSortedTodos.filter(t => t.status === 'completed').length,
    };

    return { totalCost, completedCost, progress, byPriority, byStatus };
  }, [filteredAndSortedTodos]);

  return (
    <motion.div 
      className="space-y-6 pb-20 md:pb-0"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h1 className="text-2xl md:text-3xl font-bold flex items-center gap-2">
            <ShoppingBag className="h-6 w-6 md:h-8 md:w-8 text-primary" />
            Shopping List
          </h1>
          <p className="text-sm text-muted-foreground">
            Track and manage your wedding essentials
          </p>
        </div>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <Button className="animate-bounce-in">
              <Gift className="h-4 w-4 mr-2" />
              Add Item
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Item</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="title">Title</Label>
                <Input
                  id="title"
                  value={formData.title}
                  onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                  placeholder="Enter item name"
                  disabled={isSubmitting}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="description">Description (Optional)</Label>
                <Textarea
                  id="description"
                  value={formData.description}
                  onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                  placeholder="Add any details or specifications"
                  disabled={isSubmitting}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="estimated_cost">Estimated Cost (DZD)</Label>
                <Input
                  id="estimated_cost"
                  type="number"
                  value={formData.estimated_cost}
                  onChange={(e) => setFormData(prev => ({ ...prev, estimated_cost: e.target.value }))}
                  placeholder="Enter estimated cost"
                  min="0"
                  step="100"
                  disabled={isSubmitting}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label>Category</Label>
                  <Select
                    value={formData.category}
                    onValueChange={(value) => setFormData(prev => ({ ...prev, category: value }))}
                    disabled={isSubmitting}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select category" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.entries(TODO_CATEGORIES).map(([key, { label, icon }]) => (
                        <SelectItem key={key} value={key}>
                          <span className="flex items-center">
                            <span className="mr-2">{icon}</span>
                            {label}
                          </span>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label>Priority</Label>
                  <Select
                    value={formData.priority}
                    onValueChange={(value) => setFormData(prev => ({ ...prev, priority: value }))}
                    disabled={isSubmitting}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select priority" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="low">Low</SelectItem>
                      <SelectItem value="medium">Medium</SelectItem>
                      <SelectItem value="high">High</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="flex space-x-2">
                <Button type="submit" className="flex-1" disabled={isSubmitting}>
                  {isSubmitting ? 'Adding...' : 'Add Item'}
                </Button>
                <Button
                  type="button"
                  variant="outline"
                  className="flex-1"
                  onClick={() => setOpen(false)}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>

      <div className="grid gap-4 md:gap-6 grid-cols-2 md:grid-cols-4">
        <motion.div variants={item}>
          <Card className="p-4 md:p-6 hover-lift">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium">Total Items</h3>
              <ListPlus className="h-5 w-5 text-primary" />
            </div>
            <p className="text-2xl font-bold">{filteredAndSortedTodos.length}</p>
          </Card>
        </motion.div>

        <motion.div variants={item}>
          <Card className="p-4 md:p-6 hover-lift">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium">Total Cost</h3>
              <AlertCircle className="h-5 w-5 text-primary" />
            </div>
            <p className="text-2xl font-bold">{formatCurrency(stats.totalCost)}</p>
          </Card>
        </motion.div>

        <motion.div variants={item}>
          <Card className="p-4 md:p-6 hover-lift">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium">Progress</h3>
              <Check className="h-5 w-5 text-success" />
            </div>
            <p className="text-2xl font-bold">{stats.progress.toFixed(1)}%</p>
            <Progress value={stats.progress} className="mt-2 h-2" />
          </Card>
        </motion.div>

        <motion.div variants={item}>
          <Card className="p-4 md:p-6 hover-lift">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium">High Priority</h3>
              <Clock className="h-5 w-5 text-destructive" />
            </div>
            <p className="text-2xl font-bold">{stats.byPriority.high}</p>
          </Card>
        </motion.div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search items..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="pl-9"
          />
        </div>
        <Select value={categoryFilter} onValueChange={setCategoryFilter}>
          <SelectTrigger className="w-[180px]">
            <Filter className="h-4 w-4 mr-2" />
            <SelectValue placeholder="Category" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Categories</SelectItem>
            {Object.entries(TODO_CATEGORIES).map(([key, { label, icon }]) => (
              <SelectItem key={key} value={key}>
                <span className="flex items-center">
                  <span className="mr-2">{icon}</span>
                  {label}
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select value={sortBy} onValueChange={(value: keyof typeof SORT_OPTIONS) => setSortBy(value)}>
          <SelectTrigger className="w-[180px]">
            <SortAsc className="h-4 w-4 mr-2" />
            <SelectValue placeholder="Sort by" />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(SORT_OPTIONS).map(([key, { label }]) => (
              <SelectItem key={key} value={key}>{label}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as typeof activeTab)}>
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="all">
            All Items ({filteredAndSortedTodos.length})
          </TabsTrigger>
          <TabsTrigger value="pending">
            Pending ({stats.byStatus.pending + stats.byStatus.in_progress})
          </TabsTrigger>
          <TabsTrigger value="completed">
            Completed ({stats.byStatus.completed})
          </TabsTrigger>
        </TabsList>

        <TabsContent value={activeTab}>
          <AnimatePresence>
            <motion.div 
              className="space-y-4"
              variants={container}
              initial="hidden"
              animate="show"
            >
              {filteredAndSortedTodos.length === 0 ? (
                <Card className="p-6 text-center">
                  <Gift className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
                  <p className="text-muted-foreground">No items found</p>
                  <p className="text-sm text-muted-foreground mt-1">
                    Start adding items to your wedding shopping list
                  </p>
                </Card>
              ) : (
                filteredAndSortedTodos.map((todo, index) => (
                  <motion.div
                    key={todo.id}
                    variants={item}
                    layout
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2, delay: index * 0.05 }}
                  >
                    <Card className="p-4 hover:shadow-md transition-all duration-300">
                      <div className="flex items-start justify-between">
                        <div className="space-y-1">
                          <div className="flex items-center gap-2">
                            <span className="text-2xl pulse-on-hover">
                              {TODO_CATEGORIES[todo.category].icon}
                            </span>
                            <h3 className="font-semibold">{todo.title}</h3>
                          </div>
                          {todo.description && (
                            <p className="text-sm text-muted-foreground">
                              {todo.description}
                            </p>
                          )}
                          <div className="flex flex-wrap gap-2 mt-2">
                            <Badge variant="secondary">
                              {TODO_CATEGORIES[todo.category].label}
                            </Badge>
                            <Badge className={PRIORITY_COLORS[todo.priority]}>
                              {todo.priority.charAt(0).toUpperCase() + todo.priority.slice(1)} Priority
                            </Badge>
                            <Badge className={STATUS_COLORS[todo.status]}>
                              {todo.status === 'in_progress' ? 'In Progress' : 
                               todo.status.charAt(0).toUpperCase() + todo.status.slice(1)}
                            </Badge>
                          </div>
                        </div>
                        <div className="text-right">
                          <p className="font-semibold">{formatCurrency(todo.estimated_cost)}</p>
                          <div className="flex items-center gap-2 mt-2">
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => deleteTodo(todo.id)}
                              className="hover:text-destructive"
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                            {todo.status !== 'completed' && (
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => updateTodo(todo.id, { status: 'completed' })}
                                className="hover:text-green-600"
                              >
                                <Check className="h-4 w-4" />
                              </Button>
                            )}
                            {todo.status === 'pending' && (
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => updateTodo(todo.id, { status: 'in_progress' })}
                                className="hover:text-blue-600"
                              >
                                <Clock className="h-4 w-4" />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </motion.div>
                ))
              )}
            </motion.div>
          </AnimatePresence>
        </TabsContent>
      </Tabs>
    </motion.div>
  );
}