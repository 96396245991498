import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from './supabase';
import { toast } from 'sonner';
import { useFinanceStore } from './store';

interface AuthState {
  user: any | null;
  session: any | null;
  isLoading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  checkAuth: () => Promise<void>;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      session: null,
      isLoading: true,

      signIn: async (email: string, password: string) => {
        set({ isLoading: true });
        try {
          const { data, error } = await supabase.auth.signInWithPassword({
            email: email.trim(),
            password: password.trim(),
          });

          if (error) throw error;
          if (!data.user || !data.session) {
            throw new Error('Invalid login response');
          }

          set({ 
            user: data.user, 
            session: data.session,
            isLoading: false 
          });

          // Initialize data after successful login
          await useFinanceStore.getState().initializeData();
          
          toast.success('Welcome back!');
        } catch (error: any) {
          set({ isLoading: false });
          toast.error(error.message || 'Failed to sign in');
          throw error;
        }
      },

      signUp: async (email: string, password: string) => {
        set({ isLoading: true });
        try {
          const { data, error } = await supabase.auth.signUp({
            email: email.trim(),
            password: password.trim(),
            options: {
              emailRedirectTo: window.location.origin,
              data: {
                email: email.trim(),
              }
            }
          });

          if (error) throw error;
          if (!data.user) {
            throw new Error('Failed to create account');
          }

          // Wait for database triggers to complete
          await new Promise(resolve => setTimeout(resolve, 1000));

          set({ 
            user: data.user, 
            session: data.session,
            isLoading: false 
          });

          // Initialize data after successful signup
          await useFinanceStore.getState().initializeData();
          
          toast.success('Account created successfully!');
        } catch (error: any) {
          set({ isLoading: false });
          toast.error(error.message || 'Failed to create account');
          throw error;
        }
      },

      signOut: async () => {
        try {
          await supabase.auth.signOut();
          
          // Reset all state
          set({ 
            user: null, 
            session: null,
            isLoading: false 
          });

          // Reset finance store
          useFinanceStore.getState().reset();

          // Clear storage
          localStorage.removeItem('sb-auth-token');
          localStorage.removeItem('auth-storage');
          localStorage.removeItem('finance-storage');
          
          toast.success('Signed out successfully');
        } catch (error: any) {
          console.error('Sign out error:', error);
          toast.error('Failed to sign out');
          throw error;
        }
      },

      checkAuth: async () => {
        try {
          const { data: { session }, error } = await supabase.auth.getSession();
          
          if (error) throw error;
          
          if (session) {
            const { data: { user }, error: userError } = await supabase.auth.getUser();
            if (userError) throw userError;
            
            set({ 
              user, 
              session, 
              isLoading: false 
            });

            // Initialize data after session check
            await useFinanceStore.getState().initializeData();
          } else {
            set({ 
              user: null, 
              session: null, 
              isLoading: false 
            });
          }
        } catch (error: any) {
          console.error('Auth check failed:', error);
          set({ 
            user: null, 
            session: null, 
            isLoading: false 
          });
          useFinanceStore.getState().reset();
        }
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ 
        user: state.user,
        session: state.session 
      }),
    }
  )
);