import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useFinanceStore } from '@/lib/store';
import { Calendar } from '@/components/ui/calendar';
import { Wallet, TrendingUp, TrendingDown, Target, Plus } from 'lucide-react';
import { formatCurrency } from '@/lib/utils';
import ExpenseCategories from './ExpenseCategories';
import ExpenseList from './ExpenseList';

export default function ExpensesPage() {
  const {
    dailyLimit,
    getTodayExpenses,
    getMonthlyExpenses,
    getRemainingDaily,
  } = useFinanceStore();

  const todayExpenses = getTodayExpenses();
  const monthlyExpenses = getMonthlyExpenses();
  const todayTotal = todayExpenses.reduce((sum, t) => sum + t.amount, 0);
  const monthlyTotal = monthlyExpenses.reduce((sum, t) => sum + t.amount, 0);
  const remainingDaily = getRemainingDaily();
  const avgDailySpend = monthlyTotal / 30;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold">Expense Manager</h1>
        <div className="flex space-x-4">
          <Card className="p-4">
            <p className="text-sm text-muted-foreground">Monthly Expenses</p>
            <p className="text-2xl font-bold text-destructive">
              {formatCurrency(monthlyTotal)}
            </p>
          </Card>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4">Daily Overview</h3>
          <div className="grid gap-4">
            <div className="flex items-center justify-between p-4 bg-muted rounded-lg">
              <div className="flex items-center space-x-4">
                <Wallet className="h-8 w-8 text-blue-500" />
                <div>
                  <p className="text-sm text-muted-foreground">Today's Spending</p>
                  <p className="text-xl font-bold">{formatCurrency(todayTotal)}</p>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between p-4 bg-muted rounded-lg">
              <div className="flex items-center space-x-4">
                <Target className="h-8 w-8 text-green-500" />
                <div>
                  <p className="text-sm text-muted-foreground">Remaining Today</p>
                  <p className="text-xl font-bold">{formatCurrency(remainingDaily)}</p>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between p-4 bg-muted rounded-lg">
              <div className="flex items-center space-x-4">
                {avgDailySpend > dailyLimit ? (
                  <TrendingUp className="h-8 w-8 text-red-500" />
                ) : (
                  <TrendingDown className="h-8 w-8 text-green-500" />
                )}
                <div>
                  <p className="text-sm text-muted-foreground">Avg. Daily Spend</p>
                  <p className="text-xl font-bold">{formatCurrency(avgDailySpend)}</p>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <ExpenseCategories />
      </div>

      <ExpenseList />
    </div>
  );
}