import { Heart } from 'lucide-react';
import { Progress } from '@/components/ui/progress';
import { formatCurrency } from '@/lib/utils';

interface GoalBannerProps {
  progress: number;
  goal: number;
  savings: number;
}

export default function GoalBanner({ progress, goal, savings }: GoalBannerProps) {
  const getMotivationalMessage = () => {
    if (progress >= 100) return "Congratulations! You've reached your goal! 🎉";
    if (progress >= 75) return "Almost there! The big day is getting closer! 💪";
    if (progress >= 50) return "Halfway there! Keep up the great work! 🌟";
    if (progress >= 25) return "You're making great progress! Stay focused! 🎯";
    return "Every save brings us closer to our special day! 💝";
  };

  return (
    <div className="relative overflow-hidden rounded-lg glass">
      <div className="absolute inset-0 bg-gradient-to-r from-primary/20 to-primary/10" />
      <div className="relative p-6 sm:p-8">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-6">
          <div className="space-y-2">
            <h2 className="text-xl font-semibold">Wedding Fund</h2>
            <p className="text-muted-foreground">{getMotivationalMessage()}</p>
          </div>
          <Heart className="h-8 w-8 text-primary animate-pulse" />
        </div>
        <div className="space-y-4">
          <div className="flex justify-between text-sm">
            <span className="font-medium">Progress</span>
            <span className="font-medium">{progress.toFixed(1)}%</span>
          </div>
          <Progress 
            value={progress} 
            className="h-2.5 progress-bar"
          />
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <p className="text-muted-foreground">Saved</p>
              <p className="text-lg font-semibold text-success">
                {formatCurrency(savings)}
              </p>
            </div>
            <div className="text-right">
              <p className="text-muted-foreground">Remaining</p>
              <p className="text-lg font-semibold text-primary">
                {formatCurrency(goal - savings)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}