export interface Milestone {
  id: number;
  user_id: string;
  title: string;
  description?: string;
  target_date: string;
  target_amount?: number;
  completed: boolean;
  created_at: string;
  completed_at?: string;
}

export const MILESTONE_BADGES = {
  'savings_25': {
    title: '25% Saved',
    description: 'Reached 25% of savings goal',
    icon: '🌱'
  },
  'savings_50': {
    title: '50% Saved',
    description: 'Halfway to your goal!',
    icon: '🌿'
  },
  'savings_75': {
    title: '75% Saved',
    description: 'Almost there!',
    icon: '🌳'
  },
  'savings_100': {
    title: 'Goal Achieved',
    description: 'Reached your savings goal!',
    icon: '🎉'
  }
} as const;

export const TODO_CATEGORIES = {
  furniture: {
    label: 'Furniture',
    icon: '🛋️',
    description: 'Sofas, tables, chairs, and other furniture items'
  },
  appliances: {
    label: 'Appliances',
    icon: '🔌',
    description: 'Kitchen and home appliances'
  },
  decor: {
    label: 'Decor',
    icon: '🎨',
    description: 'Decorative items and artwork'
  },
  electronics: {
    label: 'Electronics',
    icon: '📺',
    description: 'TVs, computers, and other electronics'
  },
  kitchen: {
    label: 'Kitchen',
    icon: '🍳',
    description: 'Kitchen utensils and accessories'
  },
  bathroom: {
    label: 'Bathroom',
    icon: '🚿',
    description: 'Bathroom essentials and accessories'
  },
  bedroom: {
    label: 'Bedroom',
    icon: '🛏️',
    description: 'Bedroom furniture and accessories'
  },
  other: {
    label: 'Other',
    icon: '📦',
    description: 'Other household items'
  }
} as const;

export type TransactionCategory =
  | 'food_dining'
  | 'transportation'
  | 'shopping'
  | 'bills_utilities'
  | 'entertainment'
  | 'wedding'
  | 'healthcare'
  | 'education'
  | 'gifts'
  | 'others';

export const CATEGORY_INFO = {
  food_dining: {
    label: 'Food & Dining',
    icon: '🍽️',
    description: 'Restaurants, groceries, and food delivery'
  },
  transportation: {
    label: 'Transportation',
    icon: '🚗',
    description: 'Gas, parking, public transit, and ride-sharing'
  },
  shopping: {
    label: 'Shopping',
    icon: '🛍️',
    description: 'Clothing, electronics, and general purchases'
  },
  bills_utilities: {
    label: 'Bills & Utilities',
    icon: '📱',
    description: 'Phone, internet, electricity, and other utilities'
  },
  entertainment: {
    label: 'Entertainment',
    icon: '🎬',
    description: 'Movies, games, streaming services, and events'
  },
  wedding: {
    label: 'Wedding',
    icon: '💒',
    description: 'Wedding-related expenses and preparations'
  },
  healthcare: {
    label: 'Healthcare',
    icon: '🏥',
    description: 'Medical expenses, medications, and insurance'
  },
  education: {
    label: 'Education',
    icon: '📚',
    description: 'Courses, books, and learning materials'
  },
  gifts: {
    label: 'Gifts',
    icon: '🎁',
    description: 'Presents and special occasion expenses'
  },
  others: {
    label: 'Others',
    icon: '📌',
    description: 'Miscellaneous expenses'
  }
} as const;