import { supabase } from './supabase';
import type { Profile, Transaction, Preference, TodoItem } from './db-types';

async function getSession() {
  const { data: { session }, error } = await supabase.auth.getSession();
  if (error) throw error;
  if (!session) throw new Error('No active session');
  return session;
}

export const db = {
  profiles: {
    async get() {
      const session = await getSession();
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();
      
      if (error) {
        if (error.code === 'PGRST116') {
          // Profile doesn't exist, create it
          return this.upsert({
            email: session.user.email || '',
            daily_limit: 5000,
            savings_goal: 1000000
          });
        }
        throw error;
      }
      return data as Profile;
    },
    
    async upsert(profile: Partial<Profile>) {
      const session = await getSession();
      const { data, error } = await supabase
        .from('profiles')
        .upsert({
          ...profile,
          id: session.user.id,
          updated_at: new Date().toISOString(),
        })
        .select()
        .single();
      
      if (error) throw error;
      return data as Profile;
    }
  },

  transactions: {
    async list() {
      const session = await getSession();
      const { data, error } = await supabase
        .from('transactions')
        .select('*')
        .eq('user_id', session.user.id)
        .order('date', { ascending: false });
      
      if (error) throw error;
      return data as Transaction[];
    },
    
    async add(transaction: Omit<Transaction, 'id' | 'user_id' | 'created_at'>) {
      const session = await getSession();
      const { data, error } = await supabase
        .from('transactions')
        .insert({
          ...transaction,
          user_id: session.user.id,
        })
        .select()
        .single();
      
      if (error) throw error;
      return data as Transaction;
    },
    
    async delete(id: number) {
      const session = await getSession();
      const { error } = await supabase
        .from('transactions')
        .delete()
        .eq('id', id)
        .eq('user_id', session.user.id);
      
      if (error) throw error;
    }
  },

  preferences: {
    async get() {
      const session = await getSession();
      const { data, error } = await supabase
        .from('preferences')
        .select('*')
        .eq('user_id', session.user.id)
        .single();
      
      if (error) {
        if (error.code === 'PGRST116') {
          // Preferences don't exist, create them
          return this.upsert({
            theme: 'light',
            notifications: true,
            currency: 'DZD'
          });
        }
        throw error;
      }
      return data as Preference;
    },
    
    async upsert(preferences: Partial<Preference>) {
      const session = await getSession();
      const { data, error } = await supabase
        .from('preferences')
        .upsert({
          ...preferences,
          user_id: session.user.id,
          updated_at: new Date().toISOString(),
        })
        .select()
        .single();
      
      if (error) throw error;
      return data as Preference;
    }
  },

  todos: {
    async list() {
      const session = await getSession();
      const { data, error } = await supabase
        .from('todos')
        .select('*')
        .eq('user_id', session.user.id)
        .order('created_at', { ascending: false });
      
      if (error) throw error;
      return data as TodoItem[];
    },
    
    async add(todo: Omit<TodoItem, 'id' | 'user_id' | 'created_at' | 'completed_at'>) {
      const session = await getSession();
      const { data, error } = await supabase
        .from('todos')
        .insert({
          ...todo,
          user_id: session.user.id,
        })
        .select()
        .single();
      
      if (error) throw error;
      return data as TodoItem;
    },
    
    async update(id: number, updates: Partial<TodoItem>) {
      const session = await getSession();
      const { data, error } = await supabase
        .from('todos')
        .update({
          ...updates,
          ...(updates.status === 'completed' ? { completed_at: new Date().toISOString() } : {})
        })
        .eq('id', id)
        .eq('user_id', session.user.id)
        .select()
        .single();
      
      if (error) throw error;
      return data as TodoItem;
    },
    
    async delete(id: number) {
      const session = await getSession();
      const { error } = await supabase
        .from('todos')
        .delete()
        .eq('id', id)
        .eq('user_id', session.user.id);
      
      if (error) throw error;
    }
  }
};