import { Card } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { useFinanceStore } from '@/lib/store';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { useMemo } from 'react';
import { cn } from '@/lib/utils';
import { formatCurrency } from '@/lib/utils';
import { CATEGORY_INFO, type TransactionCategory } from '@/lib/types';

const COLORS = [
  'hsl(142.1 76.2% 36.3%)', // Green
  'hsl(221.2 83.2% 53.3%)', // Blue
  'hsl(346.8 77.2% 49.8%)', // Red
  'hsl(43.3 96.4% 56.3%)',  // Yellow
  'hsl(262.1 83.3% 57.8%)', // Purple
  'hsl(189.5 94.5% 42.7%)', // Cyan
  'hsl(316.1 73.3% 52.8%)', // Pink
  'hsl(142.1 70.2% 45.3%)', // Dark Green
  'hsl(24.6 95% 53.1%)',    // Orange
  'hsl(200 95% 14%)',       // Navy
];

export default function ExpenseCategories() {
  const expenses = useFinanceStore((state) => 
    state.transactions.filter(t => t.type === 'expense')
  );

  const categoryData = useMemo(() => {
    // Create a map to store category totals
    const categoryTotals = new Map<TransactionCategory, number>();

    // Calculate totals for each category
    expenses.forEach((expense) => {
      const currentTotal = categoryTotals.get(expense.category) || 0;
      categoryTotals.set(expense.category, currentTotal + expense.amount);
    });

    // Convert to array and sort by amount
    const data = Array.from(categoryTotals.entries()).map(([category, value]) => ({
      name: CATEGORY_INFO[category].label,
      value,
      category,
      icon: CATEGORY_INFO[category].icon,
    }));

    return data.sort((a, b) => b.value - a.value);
  }, [expenses]);

  const totalExpenses = useMemo(() => 
    categoryData.reduce((sum, cat) => sum + cat.value, 0),
    [categoryData]
  );

  if (totalExpenses === 0) {
    return (
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Expense Distribution</h3>
        <div className="h-[300px] flex items-center justify-center text-center">
          <div className="space-y-2">
            <p className="text-muted-foreground">No expenses recorded yet</p>
            <p className="text-sm text-muted-foreground">
              Add some expenses to see your spending distribution
            </p>
          </div>
        </div>
      </Card>
    );
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (!active || !payload?.length) return null;
    
    const data = payload[0].payload;
    return (
      <div className="bg-card p-3 rounded-lg border shadow-lg">
        <div className="flex items-center gap-2 mb-1">
          <span>{data.icon}</span>
          <span className="font-medium">{data.name}</span>
        </div>
        <div className="text-sm space-y-1">
          <p className="font-semibold">{formatCurrency(data.value)}</p>
          <p className="text-muted-foreground">
            {((data.value / totalExpenses) * 100).toFixed(1)}% of total
          </p>
        </div>
      </div>
    );
  };

  return (
    <Card className="p-6">
      <h3 className="text-lg font-semibold mb-4">Expense Distribution</h3>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={categoryData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              label={({ name, percent }) => 
                `${name} (${(percent * 100).toFixed(0)}%)`
              }
              labelLine={true}
            >
              {categoryData.map((entry, index) => (
                <Cell 
                  key={entry.category}
                  fill={COLORS[index % COLORS.length]}
                  className="hover:opacity-80 transition-opacity"
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-6 space-y-4">
        {categoryData.map((category, index) => (
          <div key={category.category} className="space-y-2">
            <div className="flex items-center justify-between text-sm">
              <div className="flex items-center gap-2">
                <span>{category.icon}</span>
                <span className="font-medium">{category.name}</span>
              </div>
              <span className="text-muted-foreground">
                {formatCurrency(category.value)}
              </span>
            </div>
            <Progress 
              value={(category.value / totalExpenses) * 100}
              className={cn("h-2")}
              style={{ 
                '--progress-foreground': COLORS[index % COLORS.length]
              } as React.CSSProperties}
            />
          </div>
        ))}
      </div>
    </Card>
  );
}