import { Heart, Sparkles, Star, GemIcon } from 'lucide-react';
import { motion } from 'framer-motion';

const FloatingHeart = ({ delay = 0, className = "" }) => (
  <motion.div
    className={`absolute ${className}`}
    initial={{ opacity: 0, y: 10 }}
    animate={{ 
      opacity: [0, 1, 0],
      y: -20,
    }}
    transition={{
      duration: 2,
      delay,
      repeat: Infinity,
      repeatDelay: 3
    }}
  >
    <Heart className="h-3 w-3 text-pink-400" fill="currentColor" />
  </motion.div>
);

export default function Footer() {
  return (
    <footer className="py-12 border-t mt-auto bg-background relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-background/5" />
      
      {/* Floating Hearts */}
      <FloatingHeart className="left-1/4 bottom-20" delay={0} />
      <FloatingHeart className="left-1/3 bottom-16" delay={0.5} />
      <FloatingHeart className="right-1/4 bottom-24" delay={1} />
      <FloatingHeart className="right-1/3 bottom-20" delay={1.5} />

      <div className="container relative">
        <motion.div 
          className="text-center space-y-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Title with Gem Icons */}
          <motion.div
            className="inline-flex items-center gap-3 bg-primary/5 px-6 py-3 rounded-full"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <GemIcon className="h-5 w-5 text-primary" />
            <span className="font-medium text-lg">To My Future Wife</span>
            <GemIcon className="h-5 w-5 text-primary" />
          </motion.div>
          
          <div className="max-w-md mx-auto space-y-6">
            {/* Main Message */}
            <div className="relative">
              <Sparkles className="absolute -left-6 -top-6 h-5 w-5 text-yellow-400 animate-pulse" />
              <p className="text-sm text-muted-foreground font-serif italic leading-relaxed">
                "In every saved dinar, there's a piece of our dream. In every plan we make, 
                there's a promise of tomorrow. You're not just my future wife – you're my 
                destiny, my home, and my greatest adventure. Together, we're not just saving 
                for a wedding, we're investing in a lifetime of happiness."
              </p>
              <Star className="absolute -right-6 -bottom-6 h-5 w-5 text-yellow-400 animate-pulse" />
            </div>
            
            {/* Journey Timeline */}
            <motion.div 
              className="flex items-center justify-center gap-4 text-sm py-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              {['Yesterday', 'Today', 'Forever'].map((text, index) => (
                <div key={text} className="flex items-center">
                  {index > 0 && (
                    <div className="h-[1px] w-8 bg-primary/20 mx-2" />
                  )}
                  <motion.div
                    className="px-4 py-1 rounded-full bg-primary/5 text-xs"
                    whileHover={{ scale: 1.1 }}
                  >
                    {text}
                  </motion.div>
                </div>
              ))}
            </motion.div>

            {/* Love Counter */}
            <div className="text-sm text-muted-foreground space-y-2">
              <div className="flex items-center justify-center gap-2">
                <Heart className="h-4 w-4 text-pink-400" fill="currentColor" />
                <span className="font-medium">Infinite Love</span>
                <Heart className="h-4 w-4 text-pink-400" fill="currentColor" />
              </div>
              <p className="text-xs">Growing stronger with each passing moment</p>
            </div>
          </div>

          {/* Signature */}
          <motion.div 
            className="text-xs text-muted-foreground font-serif italic"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            Crafted with love & dedication, just like our future together
          </motion.div>
        </motion.div>
      </div>
    </footer>
  );
}