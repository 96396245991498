import { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Progress } from '@/components/ui/progress';
import { Home, CreditCard, Wallet, Calculator, Calendar, AlertTriangle, CheckCircle2, Clock } from 'lucide-react';
import QuickExpense from './QuickExpense';
import QuickIncome from './QuickIncome';
import GoalBanner from './GoalBanner';
import WeddingTimeline from './WeddingTimeline';
import RecentTransactions from './RecentTransactions';
import { useFinanceStore } from '@/lib/store';
import { TODO_CATEGORIES } from '@/lib/types';
import { formatCurrency } from '@/lib/utils';

const PRIORITY_COLORS = {
  low: 'bg-blue-100 text-blue-700',
  medium: 'bg-yellow-100 text-yellow-700',
  high: 'bg-red-100 text-red-700',
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  show: { y: 0, opacity: 1 }
};

export default function Dashboard() {
  const {
    savings,
    goal,
    dailyLimit,
    getTodayExpenses,
    getMonthlyExpenses,
    getRemainingDaily,
    getProgress,
    todos,
    setCurrentView,
    userProfile
  } = useFinanceStore();

  const todayExpenses = getTodayExpenses();
  const monthlyExpenses = getMonthlyExpenses();
  const todayTotal = todayExpenses.reduce((sum, t) => sum + t.amount, 0);
  const monthlyTotal = monthlyExpenses.reduce((sum, t) => sum + t.amount, 0);
  const remainingDaily = getRemainingDaily();
  const progress = getProgress();
  const avgDailySpend = monthlyTotal / 30;

  const getSpendingStatus = () => {
    if (todayTotal === 0) return { text: 'No Expenses', color: 'bg-gray-100 text-gray-700', icon: Clock };
    if (todayTotal > dailyLimit) return { text: 'Over Limit', color: 'bg-red-100 text-red-700', icon: AlertTriangle };
    return { text: 'Under Limit', color: 'bg-green-100 text-green-700', icon: CheckCircle2 };
  };

  const status = getSpendingStatus();
  const StatusIcon = status.icon;

  return (
    <motion.div
      className="space-y-4 md:space-y-6"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        <div className="space-y-1">
          <h1 className="text-2xl md:text-3xl font-bold">Dashboard</h1>
          {userProfile.weddingDate && (
            <p className="text-sm text-muted-foreground">
              {Math.max(0, Math.floor((new Date(userProfile.weddingDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)))} days until the big day!
            </p>
          )}
        </div>
        <div className="flex flex-wrap gap-2">
          <QuickIncome />
          <QuickExpense />
        </div>
      </div>

      <motion.div variants={item}>
        <GoalBanner progress={progress} goal={goal} savings={savings} />
      </motion.div>

      <div className="grid gap-3 md:gap-6 grid-cols-2 md:grid-cols-4">
        <motion.div variants={item}>
          <Card className="p-4 md:p-6 card-compact">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-muted-foreground">Today's Spending</p>
                <p className="text-lg md:text-2xl font-bold text-destructive">
                  -{formatCurrency(todayTotal)}
                </p>
              </div>
              <div className={`p-2 md:p-3 rounded-full ${status.color}`}>
                <StatusIcon className="h-5 w-5 md:h-8 md:w-8" />
              </div>
            </div>
          </Card>
        </motion.div>

        <motion.div variants={item}>
          <Card className="p-4 md:p-6 card-compact">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-muted-foreground">Monthly Total</p>
                <p className="text-lg md:text-2xl font-bold text-destructive">
                  -{formatCurrency(monthlyTotal)}
                </p>
              </div>
              <div className="p-2 md:p-3 rounded-full bg-primary/10">
                <CreditCard className="h-5 w-5 md:h-8 md:w-8 text-primary animate-pulse" />
              </div>
            </div>
          </Card>
        </motion.div>

        <motion.div variants={item}>
          <Card className="p-4 md:p-6 card-compact">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-muted-foreground">Remaining Today</p>
                <p className="text-lg md:text-2xl font-bold text-success">
                  {formatCurrency(remainingDaily)}
                </p>
              </div>
              <div className="p-2 md:p-3 rounded-full bg-green-100">
                <Wallet className="h-5 w-5 md:h-8 md:w-8 text-success animate-pulse" />
              </div>
            </div>
          </Card>
        </motion.div>

        <motion.div variants={item}>
          <Card className="p-4 md:p-6 card-compact">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-muted-foreground">Daily Average</p>
                <p className="text-lg md:text-2xl font-bold">
                  {formatCurrency(avgDailySpend)}
                </p>
              </div>
              <div className="p-2 md:p-3 rounded-full bg-primary/10">
                <Calculator className="h-5 w-5 md:h-8 md:w-8 text-primary animate-pulse" />
              </div>
            </div>
          </Card>
        </motion.div>
      </div>

      <motion.div variants={item}>
        <WeddingTimeline />
      </motion.div>

      <div className="grid gap-6 md:grid-cols-2">
        <motion.div variants={item}>
          <Card className="p-6 hover:shadow-lg transition-all duration-300">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-lg font-semibold">Shopping List Overview</h2>
              <Button variant="outline" size="sm" onClick={() => setCurrentView('todos')}>
                View All
              </Button>
            </div>
            <div className="space-y-4">
              {todos.filter(t => t.status !== 'completed').slice(0, 3).map(todo => (
                <motion.div
                  key={todo.id}
                  className="flex items-center justify-between p-3 bg-muted/50 rounded-lg hover:bg-muted transition-colors"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <div className="flex items-center space-x-3">
                    <span className="text-2xl">{TODO_CATEGORIES[todo.category].icon}</span>
                    <div>
                      <p className="font-medium">{todo.title}</p>
                      <Badge className={PRIORITY_COLORS[todo.priority]} variant="secondary">
                        {todo.priority} priority
                      </Badge>
                    </div>
                  </div>
                  <p className="font-semibold">{formatCurrency(todo.estimated_cost)}</p>
                </motion.div>
              ))}
              {todos.filter(t => t.status !== 'completed').length === 0 && (
                <p className="text-center text-muted-foreground py-4">
                  No pending items in your shopping list
                </p>
              )}
            </div>
          </Card>
        </motion.div>

        <motion.div variants={item}>
          <RecentTransactions />
        </motion.div>
      </div>
    </motion.div>
  );
}