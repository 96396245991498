import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useFinanceStore } from '@/lib/store';
import { useAuthStore } from '@/lib/auth-store';
import { toast } from 'sonner';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format } from 'date-fns';
import { CalendarIcon, User, Bell, DollarSign, LogOut } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from './theme-provider';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';

export default function SettingsPage() {
  const { theme, setTheme } = useTheme();
  const { userProfile, preferences, updateUserProfile, updatePreferences, updateDailyLimit, dailyLimit } = useFinanceStore();
  const { user, signOut } = useAuthStore();
  const [date, setDate] = useState<Date | undefined>(
    userProfile.weddingDate ? new Date(userProfile.weddingDate) : undefined
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const handleProfileUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const formData = new FormData(e.currentTarget);
      await updateUserProfile({
        name: formData.get('name') as string || null,
        partner_name: formData.get('partnerName') as string || null,
        wedding_date: date?.toISOString().split('T')[0] || null,
        email: user?.email || userProfile.email, // Ensure email is always set
      });
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error('Failed to update profile');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDailyLimitUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const formData = new FormData(e.currentTarget);
      const newLimit = parseFloat(formData.get('dailyLimit') as string);
      if (!isNaN(newLimit) && newLimit > 0) {
        await updateDailyLimit(newLimit);
        toast.success('Daily limit updated successfully');
      } else {
        toast.error('Please enter a valid amount');
      }
    } catch (error) {
      console.error('Failed to update daily limit:', error);
      toast.error('Failed to update daily limit');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleThemeChange = async (newTheme: 'light' | 'dark' | 'system') => {
    try {
      setTheme(newTheme);
      await updatePreferences({ theme: newTheme });
      toast.success('Theme updated successfully');
    } catch (error) {
      console.error('Failed to update theme:', error);
      toast.error('Failed to update theme');
    }
  };

  const handleNotificationsChange = async (enabled: boolean) => {
    try {
      await updatePreferences({ notifications: enabled });
      toast.success('Notification preferences updated');
    } catch (error) {
      console.error('Failed to update notifications:', error);
      toast.error('Failed to update notifications');
    }
  };

  return (
    <div className="space-y-6 animate-fade-in">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold">Settings</h1>
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="destructive" className="flex items-center gap-2">
              <LogOut className="h-4 w-4" />
              Logout
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure you want to logout?</AlertDialogTitle>
              <AlertDialogDescription>
                You will need to sign in again to access your account.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={signOut} className="bg-destructive text-destructive-foreground hover:bg-destructive/90">
                Logout
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>

      <Tabs defaultValue="profile" className="space-y-6">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="profile" className="flex items-center gap-2">
            <User className="h-4 w-4" />
            Profile
          </TabsTrigger>
          <TabsTrigger value="preferences" className="flex items-center gap-2">
            <Bell className="h-4 w-4" />
            Preferences
          </TabsTrigger>
          <TabsTrigger value="limits" className="flex items-center gap-2">
            <DollarSign className="h-4 w-4" />
            Spending Limits
          </TabsTrigger>
        </TabsList>

        <TabsContent value="profile" className="animate-slide-up">
          <Card className="p-6">
            <form onSubmit={handleProfileUpdate} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  value={user?.email || userProfile.email}
                  disabled
                  className="bg-muted"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="name">Your Name</Label>
                <Input
                  id="name"
                  name="name"
                  defaultValue={userProfile.name || ''}
                  disabled={isUpdating}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="partnerName">Partner's Name</Label>
                <Input
                  id="partnerName"
                  name="partnerName"
                  defaultValue={userProfile.partnerName || ''}
                  disabled={isUpdating}
                />
              </div>
              <div className="space-y-2">
                <Label>Wedding Date</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                      )}
                      disabled={isUpdating}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {date ? format(date, "PPP") : "Pick a date"}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={date}
                      onSelect={setDate}
                      disabled={(date) =>
                        date < new Date() || date > new Date(2100, 0, 1)
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <Button type="submit" disabled={isUpdating}>
                {isUpdating ? "Updating..." : "Update Profile"}
              </Button>
            </form>
          </Card>
        </TabsContent>

        <TabsContent value="preferences" className="animate-slide-up">
          <Card className="p-6 space-y-6">
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <Label>Theme</Label>
                  <p className="text-sm text-muted-foreground">
                    Choose your preferred theme
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  {["light", "dark", "system"].map((value) => (
                    <Button
                      key={value}
                      variant={theme === value ? "default" : "outline"}
                      size="sm"
                      onClick={() => handleThemeChange(value as "light" | "dark" | "system")}
                      className="capitalize"
                    >
                      {value}
                    </Button>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <Label>Notifications</Label>
                  <p className="text-sm text-muted-foreground">
                    Enable or disable notifications
                  </p>
                </div>
                <Switch
                  checked={preferences.notifications}
                  onCheckedChange={handleNotificationsChange}
                />
              </div>
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="limits" className="animate-slide-up">
          <Card className="p-6">
            <form onSubmit={handleDailyLimitUpdate} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="dailyLimit">Daily Spending Limit (DZD)</Label>
                <Input
                  id="dailyLimit"
                  name="dailyLimit"
                  type="number"
                  defaultValue={dailyLimit}
                  min="0"
                  step="100"
                  disabled={isUpdating}
                />
                <p className="text-sm text-muted-foreground">
                  Set your daily spending limit to help manage your expenses
                </p>
              </div>
              <Button type="submit" disabled={isUpdating}>
                {isUpdating ? "Updating..." : "Update Limit"}
              </Button>
            </form>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}