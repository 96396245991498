import { useMemo } from 'react';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Progress } from '@/components/ui/progress';
import { CalendarDays, Target, Trophy, Clock } from 'lucide-react';
import { useFinanceStore } from '@/lib/store';
import { formatCurrency } from '@/lib/utils';
import { MILESTONE_BADGES } from '@/lib/types';
import { differenceInDays, format, isBefore } from 'date-fns';

export default function WeddingTimeline() {
  const { userProfile, savings, goal, getProgress } = useFinanceStore();
  const progress = getProgress();

  const timelineInfo = useMemo(() => {
    if (!userProfile.weddingDate) return null;

    const weddingDate = new Date(userProfile.weddingDate);
    const today = new Date();
    const daysRemaining = differenceInDays(weddingDate, today);
    const isInPast = isBefore(weddingDate, today);

    return {
      date: format(weddingDate, 'MMMM d, yyyy'),
      daysRemaining,
      isInPast
    };
  }, [userProfile.weddingDate]);

  const milestones = useMemo(() => {
    const completed = [];
    const upcoming = [];

    Object.entries(MILESTONE_BADGES).forEach(([key, badge]) => {
      const threshold = parseInt(key.split('_')[1]);
      const isCompleted = progress >= threshold;
      const milestone = {
        ...badge,
        progress: threshold,
        completed: isCompleted,
        amount: (threshold / 100) * goal
      };

      if (isCompleted) {
        completed.push(milestone);
      } else {
        upcoming.push(milestone);
      }
    });

    return { completed, upcoming };
  }, [progress, goal]);

  if (!timelineInfo) {
    return (
      <Card className="p-6 text-center text-muted-foreground">
        <p>Set your wedding date in settings to see the timeline</p>
      </Card>
    );
  }

  return (
    <Card className="p-6 space-y-6 animate-fade-in">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold">Wedding Timeline</h2>
        <Badge variant={timelineInfo.isInPast ? "destructive" : "secondary"}>
          {timelineInfo.isInPast ? 'Past Event' : `${timelineInfo.daysRemaining} days remaining`}
        </Badge>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <div className="space-y-4">
          <div className="flex items-center space-x-3">
            <div className="bg-primary/10 p-2 rounded-full">
              <CalendarDays className="h-5 w-5 text-primary" />
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Wedding Date</p>
              <p className="font-semibold">{timelineInfo.date}</p>
            </div>
          </div>

          <div className="flex items-center space-x-3">
            <div className="bg-primary/10 p-2 rounded-full">
              <Target className="h-5 w-5 text-primary" />
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Savings Goal</p>
              <p className="font-semibold">{formatCurrency(goal)}</p>
            </div>
          </div>

          <div className="flex items-center space-x-3">
            <div className="bg-primary/10 p-2 rounded-full">
              <Trophy className="h-5 w-5 text-primary" />
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Current Savings</p>
              <p className="font-semibold">{formatCurrency(savings)}</p>
            </div>
          </div>

          <div className="flex items-center space-x-3">
            <div className="bg-primary/10 p-2 rounded-full">
              <Clock className="h-5 w-5 text-primary" />
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Daily Target</p>
              <p className="font-semibold">
                {formatCurrency(Math.max(0, (goal - savings) / Math.max(1, timelineInfo.daysRemaining)))}
              </p>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <div className="flex justify-between text-sm mb-2">
              <span className="text-muted-foreground">Overall Progress</span>
              <span className="font-medium">{progress.toFixed(1)}%</span>
            </div>
            <Progress value={progress} className="h-2" />
          </div>

          <div className="space-y-4">
            <h3 className="text-sm font-medium">Milestones</h3>
            <div className="space-y-3">
              {milestones.completed.map((milestone) => (
                <div
                  key={milestone.title}
                  className="flex items-center justify-between p-2 bg-green-50 dark:bg-green-950/30 rounded-lg animate-scale-up"
                >
                  <div className="flex items-center space-x-2">
                    <span className="text-xl">{milestone.icon}</span>
                    <div>
                      <p className="text-sm font-medium">{milestone.title}</p>
                      <p className="text-xs text-muted-foreground">
                        {formatCurrency(milestone.amount)}
                      </p>
                    </div>
                  </div>
                  <Badge variant="success">Completed</Badge>
                </div>
              ))}
              {milestones.upcoming.map((milestone) => (
                <div
                  key={milestone.title}
                  className="flex items-center justify-between p-2 bg-muted rounded-lg"
                >
                  <div className="flex items-center space-x-2">
                    <span className="text-xl opacity-50">{milestone.icon}</span>
                    <div>
                      <p className="text-sm font-medium">{milestone.title}</p>
                      <p className="text-xs text-muted-foreground">
                        {formatCurrency(milestone.amount)}
                      </p>
                    </div>
                  </div>
                  <Badge variant="secondary">
                    {formatCurrency(milestone.amount - savings)} to go
                  </Badge>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}