import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Progress } from '@/components/ui/progress';
import { Cat, Target, ArrowUpCircle, Coins, Calculator } from 'lucide-react';
import { useFinanceStore } from '@/lib/store';
import { toast } from 'sonner';
import { formatCurrency } from '@/lib/utils';
import SavingsGoalCalculator from './SavingsGoalCalculator';
import SavingsHistory from './SavingsHistory';

export default function SavingsPage() {
  const { savings, goal, getProgress } = useFinanceStore();
  const [showCalculator, setShowCalculator] = useState(false);
  
  const progress = getProgress();
  const remainingToGoal = Math.max(0, goal - savings);
  const monthlyTargetToReachGoal = remainingToGoal / 12;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold">Savings Manager</h1>
        <Dialog open={showCalculator} onOpenChange={setShowCalculator}>
          <DialogTrigger asChild>
            <Button variant="outline">
              <Calculator className="h-4 w-4 mr-2" />
              Goal Calculator
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Savings Goal Calculator</DialogTitle>
            </DialogHeader>
            <SavingsGoalCalculator onClose={() => setShowCalculator(false)} />
          </DialogContent>
        </Dialog>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <Card className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h3 className="text-lg font-semibold">Current Progress</h3>
              <p className="text-sm text-muted-foreground">Towards Wedding Fund</p>
            </div>
            <Cat className="h-8 w-8 text-blue-500 animate-bounce" />
          </div>
          <div className="space-y-4">
            <div className="flex justify-between text-sm">
              <span>Progress</span>
              <span>{progress.toFixed(1)}%</span>
            </div>
            <Progress value={progress} className="h-2" />
            <div className="grid grid-cols-2 gap-4 pt-4">
              <div className="space-y-1">
                <p className="text-sm text-muted-foreground">Saved</p>
                <p className="text-2xl font-bold text-green-600">
                  {formatCurrency(savings)}
                </p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-muted-foreground">Remaining</p>
                <p className="text-2xl font-bold text-blue-600">
                  {formatCurrency(remainingToGoal)}
                </p>
              </div>
            </div>
          </div>
        </Card>

        <Card className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h3 className="text-lg font-semibold">Monthly Target</h3>
              <p className="text-sm text-muted-foreground">To Reach Goal</p>
            </div>
            <Target className="h-8 w-8 text-green-500" />
          </div>
          <div className="space-y-4">
            <div className="bg-muted p-4 rounded-lg">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-muted-foreground">Monthly Savings Needed</p>
                  <p className="text-2xl font-bold">{formatCurrency(monthlyTargetToReachGoal)}</p>
                </div>
                <Coins className="h-8 w-8 text-yellow-500" />
              </div>
              <p className="text-sm text-muted-foreground mt-2">
                Based on a 12-month savings plan
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-muted p-4 rounded-lg">
                <p className="text-sm text-muted-foreground">Weekly Target</p>
                <p className="text-xl font-bold">
                  {formatCurrency(monthlyTargetToReachGoal / 4)}
                </p>
              </div>
              <div className="bg-muted p-4 rounded-lg">
                <p className="text-sm text-muted-foreground">Daily Target</p>
                <p className="text-xl font-bold">
                  {formatCurrency(monthlyTargetToReachGoal / 30)}
                </p>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <SavingsHistory />
    </div>
  );
}