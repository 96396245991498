import { useState } from 'react';
import { Home, Coins, Receipt, Settings, LogOut, Menu, ShoppingBag } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useAuthStore } from '@/lib/auth-store';
import { useFinanceStore } from '@/lib/store';
import { Button } from './ui/button';
import { Sheet, SheetContent, SheetTrigger } from './ui/sheet';

export default function Navigation() {
  const { signOut } = useAuthStore();
  const [isOpen, setIsOpen] = useState(false);
  const currentView = useFinanceStore((state) => state.currentView);
  const setCurrentView = useFinanceStore((state) => state.setCurrentView);

  const navItems = [
    { id: 'dashboard', label: 'Home', icon: Home },
    { id: 'expenses', label: 'Expenses', icon: Receipt },
    { id: 'savings', label: 'Savings', icon: Coins },
    { id: 'todos', label: 'List', icon: ShoppingBag },
    { id: 'settings', label: 'Settings', icon: Settings },
  ];

  const NavContent = () => (
    <>
      {navItems.map(({ id, label, icon: Icon }) => (
        <button
          key={id}
          onClick={() => {
            setCurrentView(id);
            setIsOpen(false);
          }}
          className={cn(
            'nav-item',
            currentView === id ? 'nav-item-active' : 'nav-item-inactive'
          )}
        >
          <Icon className="h-4 w-4" />
          <span>{label}</span>
        </button>
      ))}
    </>
  );

  return (
    <>
      {/* Desktop Navigation */}
      <nav className="sticky top-0 z-50 border-b bg-card/80 backdrop-blur supports-[backdrop-filter]:bg-card/60 hidden md:block">
        <div className="container mx-auto">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center space-x-2">
              <Home className="h-6 w-6 text-primary animate-pulse" />
              <span className="text-lg font-semibold">SaveForUs</span>
            </div>

            <div className="hidden md:flex items-center space-x-4">
              <NavContent />
              <Button
                variant="ghost"
                size="sm"
                onClick={signOut}
                className="text-destructive hover:text-destructive hover:bg-destructive/10"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Logout
              </Button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 z-50 bg-background border-t safe-area-bottom">
        <div className="grid grid-cols-5 gap-1 p-2">
          {navItems.map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setCurrentView(id)}
              className={cn(
                'flex flex-col items-center justify-center py-2 px-1 rounded-lg transition-colors',
                currentView === id 
                  ? 'text-primary bg-primary/10' 
                  : 'text-muted-foreground hover:text-foreground hover:bg-muted'
              )}
            >
              <Icon className="h-5 w-5" />
              <span className="text-xs mt-1">{label}</span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
}