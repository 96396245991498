import { useEffect, useState } from 'react';
import { ThemeProvider } from '@/components/theme-provider';
import Dashboard from '@/components/Dashboard';
import Navigation from '@/components/Navigation';
import ExpensesPage from '@/components/ExpensesPage';
import SavingsPage from '@/components/SavingsPage';
import SettingsPage from '@/components/SettingsPage';
import TodosPage from '@/components/TodosPage';
import Footer from '@/components/Footer';
import { Toaster } from 'sonner';
import { useFinanceStore } from '@/lib/store';
import { useAuthStore } from '@/lib/auth-store';
import AuthForm from '@/components/auth/AuthForm';
import LoadingScreen from '@/components/LoadingScreen';
import ErrorBoundary from '@/components/ErrorBoundary';
import { motion, AnimatePresence } from 'framer-motion';

function App() {
  const currentView = useFinanceStore((state) => state.currentView);
  const preferences = useFinanceStore((state) => state.preferences);
  const initializeData = useFinanceStore((state) => state.initializeData);
  const { user, isLoading, checkAuth } = useAuthStore();
  const [isInitializing, setIsInitializing] = useState(false);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    if (user && !isInitializing) {
      setIsInitializing(true);
      initializeData()
        .catch(console.error)
        .finally(() => setIsInitializing(false));
    }
  }, [user, initializeData, isInitializing]);

  if (isLoading || isInitializing) {
    return <LoadingScreen />;
  }

  if (!user) {
    return (
      <ThemeProvider defaultTheme="light" storageKey="finance-theme">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <AuthForm />
        </motion.div>
        <Toaster />
      </ThemeProvider>
    );
  }

  const renderContent = () => {
    switch (currentView) {
      case 'dashboard':
        return <Dashboard />;
      case 'expenses':
        return <ExpensesPage />;
      case 'savings':
        return <SavingsPage />;
      case 'todos':
        return <TodosPage />;
      case 'settings':
        return <SettingsPage />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <ThemeProvider defaultTheme={preferences?.theme || 'light'} storageKey="finance-theme">
      <ErrorBoundary>
        <div className="min-h-screen bg-background flex flex-col">
          <Navigation />
          <main className="container mx-auto px-4 py-6 flex-1">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentView}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                {renderContent()}
              </motion.div>
            </AnimatePresence>
          </main>
          <div className="hidden md:block">
            <Footer />
          </div>
        </div>
        <Toaster />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;