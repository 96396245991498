import { Card } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useFinanceStore } from '@/lib/store';
import { ArrowUpCircle, ArrowDownCircle } from 'lucide-react';
import { formatCurrency } from '@/lib/utils';

export default function SavingsHistory() {
  const transactions = useFinanceStore((state) => state.transactions);

  // Group transactions by month
  const groupedTransactions = transactions.reduce((groups, transaction) => {
    const date = new Date(transaction.date);
    const monthYear = date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    
    if (!groups[monthYear]) {
      groups[monthYear] = {
        income: 0,
        expenses: 0,
        netSavings: 0,
        transactions: [],
      };
    }
    
    const group = groups[monthYear];
    if (transaction.type === 'income') {
      group.income += transaction.amount;
    } else {
      group.expenses += transaction.amount;
    }
    group.netSavings = group.income - group.expenses;
    group.transactions.push(transaction);
    
    return groups;
  }, {} as Record<string, { income: number; expenses: number; netSavings: number; transactions: typeof transactions }>);

  return (
    <Card className="p-6">
      <h3 className="text-lg font-semibold mb-4">Savings History</h3>
      <ScrollArea className="h-[400px]">
        <div className="space-y-6 pr-4">
          {Object.entries(groupedTransactions).map(([monthYear, data]) => (
            <div key={monthYear} className="space-y-4">
              <div className="sticky top-0 bg-background pt-2 pb-4">
                <div className="flex items-center justify-between">
                  <h4 className="font-medium">{monthYear}</h4>
                  <div className="flex items-center space-x-4 text-sm">
                    <span className="text-green-600">+{formatCurrency(data.income)}</span>
                    <span className="text-red-600">-{formatCurrency(data.expenses)}</span>
                    <span className={data.netSavings >= 0 ? 'text-green-600' : 'text-red-600'}>
                      Net: {formatCurrency(data.netSavings)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                {data.transactions.map((transaction) => (
                  <div
                    key={transaction.id}
                    className="flex items-center justify-between p-3 bg-muted/50 rounded-lg"
                  >
                    <div className="flex items-center space-x-4">
                      {transaction.type === 'income' ? (
                        <ArrowUpCircle className="h-5 w-5 text-green-500" />
                      ) : (
                        <ArrowDownCircle className="h-5 w-5 text-red-500" />
                      )}
                      <div>
                        <p className="font-medium">{transaction.description}</p>
                        <p className="text-sm text-muted-foreground">
                          {new Date(transaction.date).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <span
                      className={`font-semibold ${
                        transaction.type === 'income'
                          ? 'text-green-600'
                          : 'text-red-600'
                      }`}
                    >
                      {transaction.type === 'income' ? '+' : '-'}
                      {formatCurrency(transaction.amount)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </ScrollArea>
    </Card>
  );
}