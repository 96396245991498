import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useFinanceStore } from '@/lib/store';
import { toast } from 'sonner';
import { formatCurrency } from '@/lib/utils';

interface SavingsGoalCalculatorProps {
  onClose: () => void;
}

export default function SavingsGoalCalculator({ onClose }: SavingsGoalCalculatorProps) {
  const { updateGoal } = useFinanceStore();
  const [formData, setFormData] = useState({
    targetAmount: '',
    months: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const amount = parseFloat(formData.targetAmount);
    const months = parseInt(formData.months);

    if (isNaN(amount) || amount <= 0) {
      toast.error('Please enter a valid target amount');
      return;
    }

    if (isNaN(months) || months <= 0) {
      toast.error('Please enter a valid number of months');
      return;
    }

    setIsSubmitting(true);
    try {
      await updateGoal(amount);
      toast.success(`Goal set to ${formatCurrency(amount)}`);
      onClose();
    } catch (error) {
      console.error('Failed to update goal:', error);
      toast.error('Failed to update goal');
    } finally {
      setIsSubmitting(false);
    }
  };

  const monthlyAmount = 
    formData.targetAmount && formData.months
      ? parseFloat(formData.targetAmount) / parseInt(formData.months)
      : 0;

  return (
    <div className="space-y-6">
      <DialogHeader>
        <DialogTitle>Calculate Your Savings Goal</DialogTitle>
      </DialogHeader>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="targetAmount">Target Amount</Label>
          <Input
            id="targetAmount"
            type="number"
            placeholder="Enter target amount"
            value={formData.targetAmount}
            onChange={(e) => setFormData(prev => ({ ...prev, targetAmount: e.target.value }))}
            min="0"
            step="0.01"
            disabled={isSubmitting}
            required
          />
        </div>
        
        <div className="space-y-2">
          <Label htmlFor="months">Number of Months</Label>
          <Input
            id="months"
            type="number"
            placeholder="Enter number of months"
            value={formData.months}
            onChange={(e) => setFormData(prev => ({ ...prev, months: e.target.value }))}
            min="1"
            step="1"
            disabled={isSubmitting}
            required
          />
        </div>

        {monthlyAmount > 0 && (
          <div className="p-4 bg-muted rounded-lg space-y-2">
            <p className="text-sm font-medium">Monthly Savings Required</p>
            <p className="text-2xl font-bold">{formatCurrency(monthlyAmount)}</p>
            <div className="grid grid-cols-2 gap-2 mt-2">
              <div>
                <p className="text-sm text-muted-foreground">Weekly</p>
                <p className="font-medium">{formatCurrency(monthlyAmount / 4)}</p>
              </div>
              <div>
                <p className="text-sm text-muted-foreground">Daily</p>
                <p className="font-medium">{formatCurrency(monthlyAmount / 30)}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flex space-x-2">
          <Button 
            type="submit" 
            className="flex-1"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Updating...' : 'Update Goal'}
          </Button>
          <Button 
            type="button" 
            variant="outline" 
            onClick={onClose} 
            className="flex-1"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}